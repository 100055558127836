import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@common/components/icon';
import { Modal } from '@common/components/modal';
import { Button } from '@common/components/button';
import { FormIcon } from '../../components/form-icon';
import Label from '@common/components/form/label';
import { Group, Row, TextInput } from '@common/components/form';

import type { FormPayload } from '../../actions';
import { useParams } from 'react-router-dom';

const availableColors = [
  '#007AFF',
  '#3C9EEF',
  '#33B29C',
  '#42B35D',
  '#F6AE35',
  '#FF9500',
  '#FCD34D',
  '#F78C36',
  '#F76E63',
  '#F77995',
  '#F57ECB',
  '#9E74FA',
  '#5856D6',
  '#AF52DE',
];

enum EFormIconPaths {
  ACCOUNT_CIRCLE = 'default/forms/account-circle.png',
  ACCOUNT_TREE = 'default/forms/account-tree.png',
  ANALYTICS = 'default/forms/analytics.png',
  ASSIGNMENT = 'default/forms/assignment.png',
  BUSINESS = 'default/forms/business.png',
  CAKE = 'default/forms/cake.png',
  CALENDAR_TODAY = 'default/forms/calendar-today.png',
  CLOTHES = 'default/forms/clothes.png',
  COFFEE = 'default/forms/coffee.png',
  EMAIL = 'default/forms/email.png',
  FAVORITE = 'default/forms/favorite.png',
  FLIGHT = 'default/forms/flight.png',
  FOLDER = 'default/forms/folder.png',
  LOCK = 'default/forms/lock.png',
  MEDICAL_CROSS = 'default/forms/medical-cross.png',
  MONEY = 'default/forms/money.png',
  SCHOOL = 'default/forms/school.png',
  SETTINGS = 'default/forms/settings.png',
  SHIELD = 'default/forms/shield.png',
  SIGNATURE = 'default/forms/signature.png',
}

type Props = {
  children?: React.ReactNode;
  submitting: boolean;
  values: FormPayload & { activate_status: boolean };
  change: (field: string, value: any) => void;
  onSubmit: () => Promise<void>;
  onClose?: () => void;
};

type Params = {
  id?: string;
};

const FormFormUnconnected = ({
  children, values, submitting = false, change, onClose, onSubmit
}: Props) => {
  const { t } = useTranslation();
  const params = useParams<Params>();
  const isEditForm = !!params.id;

  return (
    <Modal
      list
      size="small"
      title={isEditForm ? t('forms:update_form') : t('forms:create_form')}
      onClose={onClose}
      content={(
        <Group>
          <Label text={t('forms:form_form_title')} />
          <Row>
            <FormIcon form={values} />
            <TextInput
              name="title"
              placeholder={t('forms:form_form_title_placeholder')}
            />
          </Row>
          <Row>
            <Group>
              <Label text={t('forms:form_form_color')} />
              <div className="Input__ColorPicker">
                {availableColors.map((hex) => (
                  <div
                    role="button"
                    style={{ backgroundColor: hex }}
                    onClick={() => change('color', hex)}
                  >
                    {values.color === hex && <Icon type="check" />}
                  </div>
                ))}
              </div>
            </Group>
          </Row>
          <Row>
            <Group>
              <Label text={t('forms:form_form_icon')} />
              <div className="Input__IconPicker">
                {Object.values(EFormIconPaths).map((path) => (
                  <div
                    className={values.icon === path ? 'active' : undefined}
                    onClick={() => change('icon', path)}
                    role="button"
                  >
                    <img
                      src={`https://assets.oneteam.io/globals/attachments/${path.replace('.png', '-preview.png')}`}
                      alt="Icon"
                    />
                  </div>
                ))}
              </div>
            </Group>
          </Row>
        </Group>
      )}
      footer={!!onClose && (
        <Button
          type="primary"
          buttonType="submit"
          onClick={() => onSubmit()}
          isLoading={submitting}
        >
          {t('forms:create')}
        </Button>
      )}
    >
      {children}
    </Modal>
  );
};

export const FormForm = FormFormUnconnected;
