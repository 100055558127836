import React, {
  ChangeEvent, memo, useState, SyntheticEvent, useCallback, KeyboardEvent
} from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import * as alert from '@common/services/alert';
import { FileInput } from '@common/components/form/inputs/file';
import { GifInput } from '@common/components/form/inputs/gif';
import ImagePreview from '@common/components/file-preview/image';
import Icon from '@common/components/icon';
import Dropdown from '@common/components/dropdown';
import Permission from '@common/components/permission';
import { EComponentTypes } from '@common/definitions';
import type { FileInputValue } from '@common/types/objects';
import Api from '@common/services/api';
import { EFormSubmissionCommentType, FormSubmissionComment } from '@modules/forms/types';
import useInputHeightAutogrow from '@common/hooks/use-input-height-autogrow';
import { IMAGES_ACCEPT } from '@common/components/form/inputs/file/utils';
import { Button } from '@common/components/button';
import { combineClassNames } from '@common/utils/combineClassNames';

type PostCommentProps = {
  organisationId: string;
  submissionId: string;
  addComment: (comment: FormSubmissionComment) => void;
};

const PostComment = memo(({
  organisationId,
  submissionId,
  addComment,
}: PostCommentProps) => {

  const { t } = useTranslation();

  const [attachment, setAttachment] = useState<null | FileInputValue>(null);
  const [text, setText] = useState<string>('');
  const [posting, setPosting] = useState<boolean>(false);
  const [commentType, setCommentType] = useState<EFormSubmissionCommentType>(EFormSubmissionCommentType.COMMENT);

  const isDashboard = useRouteMatch('/admin/forms/:id/edit/submissions/:submissionId');
  const isValid = !!(text && !attachment?.processing);

  const submit = useCallback(async () => {
    if (!isValid) return;

    try {
      setPosting(true);
      const payload: Record<string, any> = {};
      if (text) {
        payload.text = text;
        payload.type = commentType;
      }
      if (attachment) {
        payload.files = [attachment.id];
      }
      const { data: comment } = await Api.post(
        `/v1/organisations/${organisationId}/users/me/forms/submissions/${submissionId}/comments`,
        payload
      );
      setText('');
      setAttachment(null);
      addComment(comment as FormSubmissionComment);
    } catch (error) {
      alert.error(t('social:form_post_comment_error_posting_comment'));
      throw error;
    } finally {
      setPosting(false);
    }
  }, [
    t, text, attachment, organisationId, submissionId, isValid,
    addComment, setPosting, setText, setAttachment,
  ]);

  const onSubmit = useCallback(async (event: SyntheticEvent) => {
    event.preventDefault();
    submit();
  }, [submit]);

  const inputRef = useInputHeightAutogrow(text);

  const onKeyPress = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // prevent onChange triggering & value update
      submit();
    }
  };

  return (
    <form className="Form PostComment formSubmissionComment" onSubmit={onSubmit}>
      {
        attachment && (
          <div className="PostComment__Attachment">
            <ImagePreview
              key={attachment?.id}
              file={attachment}
              size={70}
              onRemove={() => setAttachment(null)}
            />
          </div>
        )
      }

      <div className={combineClassNames('PostComment__Container', {
        'PostComment__Container--dashboard': !!isDashboard,
        'PostComment__Container--network': !isDashboard,
      })}
      >
        <div className="tw-flex tw-items-center tw-mb-1">
          <FileInput
            value={attachment ? [attachment] : undefined}
            onChange={(file) => setAttachment(file || null)}
            disabled={!!attachment}
            accept={IMAGES_ACCEPT}
            maxFileSize={25}
          >
            <Icon type="image__filled" className="PostComment__Action" />
          </FileInput>

          <Permission component={EComponentTypes.GIPHY}>
            <GifInput
              position="bottom left"
              disabled={!!attachment}
              onChange={setAttachment}
            >
              <Icon type="gif" className="PostComment__Action" />
            </GifInput>
          </Permission>

          {isDashboard && (
            <Dropdown
              small
              position="top left"
              toggle={
                <Button
                  className="tw-ml-2 !tw-px-2 !tw-py-1 !tw-rounded-md !tw-text-gray-500
                !tw-border !tw-border-solid !tw-border-gray-300 !tw-text-base !tw-bg-transparent
                tw-flex tw-flex-row-reverse [&>svg]:!tw-ml-1 [&>svg]:!tw-mr-0"
                  icon="arrow_drop_down"
                >
                  {commentType === EFormSubmissionCommentType.COMMENT
                    ? t('social:form_post_comment_type_comment')
                    : t('social:form_post_comment_type_note')}
                </Button>
              }
              className="tw-mr-2 -tw-ml-2"
            >
              <Dropdown.Item onClick={() => setCommentType(EFormSubmissionCommentType.COMMENT)}>
                {t('social:form_post_comment_type_comment')}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setCommentType(EFormSubmissionCommentType.NOTE)}>
                {t('social:form_post_comment_type_note')}
              </Dropdown.Item>
            </Dropdown>
          )}
        </div>
        <div className="tw-flex tw-items-center tw-gap-1">
          <div className={`Form__control Form__control--multiLine 
            ${commentType === EFormSubmissionCommentType.NOTE ? '!tw-bg-form-submission-note' : ''}`}
          >
            <div className="Form__control__control autoGrowTextArea">
              <textarea
                ref={inputRef}
                placeholder={commentType === EFormSubmissionCommentType.NOTE
                  ? t('social:form_post_comment_text_placeholder_note')
                  : t('social:form_post_comment_text_placeholder')}
                className={
                  `Form__control__input ${commentType === EFormSubmissionCommentType.NOTE ? '!tw-bg-form-submission-note' : ''}`
                }
                value={text}
                style={{ height: '17px' }}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                  setText(event.target.value);
                }}
                onKeyPress={onKeyPress}
              />
            </div>
          </div>

          <button className="PostComment__SendButton" type="submit" disabled={!isValid || posting}>
            <Icon type="send__filled" className="PostComment__SentButton" />
          </button>
        </div>
      </div>
    </form>
  );
});

export default PostComment;

